<template>
    <v-container
            class="fill-height"
            fluid
    >
        <v-row>
            <v-col
                    class="d-flex justify-center"
            >
                <v-card
                        max-width="500"
                        class="flex-grow-1 elevation-12"
                >
                    <v-card-text>
                        <template v-if="alertType != 'success'">
                            <v-text-field
                                    v-if="input == 'textField'"
                                    label="Введите ваш e-mail"
                                    prepend-icon="mdi-email"
                                    type="text"
                                    v-model="email"
                                    @focus="focus"
                                    v-on:keydown.enter="getAccess"
                            />

                            <v-autocomplete
                                    ref="autocomplete"
                                    v-if="input == 'autocomplete'"
                                    label="Введите ваш e-mail"
                                    prepend-icon="mdi-email"
                                    hide-no-data
                                    append-icon=""
                                    :items="emails"
                                    :search-input.sync="search"
                                    @blur="blur()"
                                    @focus="focus"
                                    v-on:keydown.enter="getAccess"
                            ></v-autocomplete>
                        </template>

                        <v-alert
                                v-if="alertMessage"
                                :type="alertType"
                                class="mt-3"
                        >{{ alertMessage }}
                        </v-alert>

                    </v-card-text>
                    <v-card-actions class="pb-4">
                        <div
                                v-if="alertType == 'success' && !repeatLoading"
                                class="text-body-1 black-color mx-auto"
                        >
                            я не получил,
                            <span
                                    class="send-again-link green--text text--darken-4 font-weight-medium"
                                    @click="getAccess($event, true)"
                            >
                                отправить ещё раз
                            </span>
                        </div>
                        <v-progress-circular
                                v-if="repeatLoading"
                                indeterminate
                                color="green"
                                class="mx-auto"
                        ></v-progress-circular>
                        <v-btn
                                v-if="accessBtn && alertType != 'success'"
                                class="mx-auto"
                                color="success"
                                @click="getAccess"
                                :loading="loading"
                        >
                            Получить доступ
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {

    data() {
        return {
            loading: false,
            repeatLoading: false,
            email: '',
            emails: [],
            alertType: '',
            alertMessage: '',
            input: 'autocomplete',
            search: '',
            accessBtn: true
        }
    },


    created() {
        let authEmails = localStorage.getItem('authEmails')
        this.emails = authEmails ? authEmails.split(',') : []
        let lastAuthEmail = localStorage.getItem('lastAuthEmail')
        if (lastAuthEmail) {
            this.email = lastAuthEmail
            this.input = 'textField'
        }
    },


    watch: {
        email(value) {
            if (!value) this.input = 'autocomplete'
        }
    },


    methods: {

        blur() {
            this.email = this.search
            if (this.email != '')
                this.input = 'textField'
        },

        focus() {
            this.accessBtn = true
            this.alertType = ''
            this.alertMessage = ''
        },

        getAccess(event, repeat = false) {
            this.accessBtn = true
            if (this.input == 'autocomplete')
                this.email = this.search
            if (!this.email) return false

            if (repeat) {
                this.repeatLoading = true
            } else {
                this.alertType = ''
                this.alertMessage = ''
            }

            this.input = 'textField'

            this.loading = true

            return this.$store.dispatch('request', {
                controller: 'Auth',
                action: 'getAccess',
                email: this.email.trim()
            }).then(() => {
                if (!this.emails.includes(this.email))
                    this.emails.push(this.email)
                localStorage.setItem('authEmails', this.emails.join())
                localStorage.setItem('lastAuthEmail', this.email)

                if (this.$refs.autocomplete)
                    this.$refs.autocomplete.blur()
                this.alertType = 'success'
                this.alertMessage = 'Мы отправили вам особую ссылку для входа, откройте письмо и просто кликните по ссылке для входа'
                this.accessBtn = false
            }).catch(e => {
                this.alertType = 'error'
                this.alertMessage = e.message
            }).finally(() => {
                this.loading = false
                this.repeatLoading = false
            })
        }

    }
}
</script>

<style lang="scss">
.send-again-link {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>